import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AgregarFotosInmueble from "../componentes/AgregarFotosInmueble";
import AgregarGeneralesInmueble from "../componentes/AgregarGeneralesInmueble";
import "../assets/css/datos_empresa.css";

// Una vez creado el id, se habilitan todos los demas campos
const DatosEmpresa = ({ toggle_state, cambiarSeccion }) => {
  return (
    <div className="fondo-trabajo text-white">
      <div className={`container-fluid container_principal ${toggle_state}`}>
        <Router>
          <div className="inmuebles--agregar">
            <div className="inmuebles--menu">
              <ul>
                <li>
                  <Link to="/inmuebles/agregar/datos-generales">
                    <p>Datos</p>
                    <p>Generales</p>
                  </Link>
                </li>
                <li>
                  <Link to="/inmuebles/agregar/aspectos-inmueble">
                    <p>Aspectos</p>
                    <p>del</p>
                    <p>Inmueble</p>
                  </Link>
                </li>
                <li>
                  <Link to="/inmuebles/agregar/fotos-inmueble">
                    <p>Fotos</p>
                    <p>del</p>
                    <p>Inmueble</p>
                  </Link>
                </li>
                <li>
                  <Link to="/inmuebles/agregar/estatus-inmueble">
                    <p>Estatus</p>
                    <p>del</p>
                    <p>Inmueble</p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="inmuebles--agregar--content">
              <Switch>
                <Route exact path="/inmuebles/agregar/datos-generales">
                  <AgregarGeneralesInmueble />
                </Route>
                <Route path="/inmuebles/agregar/aspectos-inmueble">
                  <p>aspectos del inmueble</p>
                </Route>
                <Route path="/inmuebles/agregar/fotos-inmueble">
                  {/* <AgregarFotosInmueble /> */}
                  <AgregarFotosInmueble />
                </Route>
                <Route path="/inmuebles/agregar/estatus-inmueble">
                  <p>estatus del inmueble</p>
                </Route>
              </Switch>
            </div>
          </div>
        </Router>
      </div>
    </div>
  );
};

export default DatosEmpresa;
