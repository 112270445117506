import React, {useState, useEffect} from "react";
// import photo1 from "../assets/img/casa.jpg";
// import photo2 from "../assets/img/casa2.jpg";
// import photo3 from "../assets/img/casa3.jpg";
import iconSave from "../assets/img/save-regular.svg";

var clave = "CBR-CAGB0890";

let photo1 = require('../assets/img/casa.jpg');
let photo2 = require('../assets/img/casa2.jpg');
let photo3 = require('../assets/img/casa3.jpg');

const AgregarFotosInmueble = () => {

  const [pathImage, setPathImage] = useState("");

  var SendPath = (photo) => {
    setPathImage(photo);
    console.log(photo)
  }

  // useEffect(() => {
  //   SendPath()
  // }, [])

  return (
    <div className="galery">
      <div>
        <div className="galery--buttons">
          <button className="general-button icon--size">
            <img src={iconSave} alt="" />
          </button>
          <button className="general-button icon--size">
            <img src={iconSave} alt="" />
          </button>
          <button className="general-button icon--size">
            <img src={iconSave} alt="" />
          </button>
          <button className="general-button icon--size">
            <img src={iconSave} alt="" />
          </button>
          <button className="general-button icon--size">
            <img src={iconSave} alt="" />
          </button>
          <p>Clave: </p>
          <p className="general-button">{clave}</p>
        </div>
        <div className="galery--selection">
          <div className="fill">
            <img
            onClick = { (e) => SendPath(photo1)}
             src={photo1} alt="" />
          </div>
          <div className="fill">
            <img 
            onClick = { (e) => SendPath(photo2)}
            src={photo2} 
            alt="" />
          </div>
          <div className="fill">
            <img 
            onClick = { (e) => SendPath(photo3)}
            src={photo3} alt="" />
          </div>
          <div className="fill">
            <img src={photo1} alt="" />
          </div>
          <div className="fill">
            <img src={photo2} alt="" />
          </div>
          <div className="fill">
            <img src={photo3} alt="" />
          </div>
          <div className="fill">
            <img src={photo1} alt="" />
          </div>
          <div className="fill">
            <img src={photo2} alt="" />
          </div>
          <div className="fill">
            <img src={photo3} alt="" />
          </div>
          <div className="fill">
            <img src={photo1} alt="" />
          </div>
          <div className="fill">
            <img src={photo2} alt="" />
          </div>
          <div className="fill">
            <img src={photo3} alt="" />
          </div>
        </div>
      </div>
      <div className="galery--screen">
        <div className="fill2">
          <img src={pathImage} alt=""/>
        </div>
      </div>
    </div>
  );
};

export default AgregarFotosInmueble;
