import React from 'react';
import sVenta from '../assets/img/Icon Venta.svg';
import sRenta from '../assets/img/Icon Renta.svg';
import sRentaEsp from '../assets/img/Icon Renta Esp.svg';

const services = {'Venta': sVenta,
                    'Renta': sRenta,
                    'RentaEsp': sRentaEsp}

const Service = ({typeService, price}) => {
    return (
        <div className="service">
            <img className="icon--size--sm" src={services[typeService]} alt="Icon Venta" />
            <p className="dots--768">{`${typeService} ••• ${price}`}</p>
            <p className="dots--1024">{`${typeService} ••••••••••••• ${price}`}</p>
        </div>
    )
}

export default Service