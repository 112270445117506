import React from "react";
import Button from "./Button";

import iconPlans from "../assets/img/Icon superficie.svg";
import iconConstruction from "../assets/img/Icon m2 construidos.svg";
import iconRooms from "../assets/img/Icon recamaras.svg";
import iconBanos from "../assets/img/Icon banos.svg";

import iconMujer from "../assets/img/Icon propietario M.svg";
import iconHombre from "../assets/img/Icon agregar contacto.svg";
import iconHyM from "../assets/img/Icon agregar contacto.svg";

import iconLocation from "../assets/img/Icon ubicacion V.svg";
import iconPropietary from "../assets/img/Icon agregar contacto.svg";

const Details = ({
  address,
  company,
  superficie,
  m2construccion,
  recamaras,
  banos,
  namePropietary,
}) => {
  return (
    <React.Fragment>
      <div className="centrar-items my-row my-row-between mb-3">
        <div className="my-row">
        <div className="my-column">
            <div className="btn">
              <img className="icon--size" src={iconPlans} alt="" />
            </div>
            <p>{superficie}</p>
          </div>
          <div className="my-column">
            <div className="btn">
              <img className="icon--size" src={iconConstruction} alt="" />
            </div>
            <p>{m2construccion}</p>
          </div>
          <div className="my-column">
            <div className="btn">
              <img className="icon--size" src={iconRooms} alt="" />
            </div>
            <p>{recamaras}</p>
          </div>
          <div className="my-column">
            <div className="btn">
              <img className="icon--size" src={iconBanos} alt="" />
            </div>
            <p>{banos}</p>
          </div>
          <div className="my-column">
            <div className="btn">
              <img className="icon--size" src={iconBanos} alt="" />
            </div>
            <p>{banos}</p>
          </div>
        </div>
      </div>
      <div className="my-row my-row-between mb-3">
        <div className="my-row">
          <div className="btn">
            <img className="icon--size" src={iconMujer} alt="" />
          </div>
          <div className="btn">
            <img className="icon--size" src={iconHombre} alt="" />
          </div>
          <div className="btn">
            <img className="icon--size" src={iconHombre} alt="" />
          </div>
          <div className="btn">
            <img className="icon--size" src={iconHombre} alt="" />
          </div>
        </div>
        {/* <div className="my-row">
          <Button children="serena votre potrero" buttonStyle="btn--service" />
        </div> */}
      </div>
      <div className="my-row mb-3">
        <img
          className="icon--size--sm"
          src={iconLocation}
          alt="Icono ubicación"
        />
        <p>{address}</p>
      </div>
      <div className="my-row mb-3">
        <img
          className="icon--size--sm"
          src={iconPropietary}
          alt="Icono ubicación"
        />
        <p>{namePropietary}</p>
      </div>
    </React.Fragment>
  );
};

export default Details;
