import React from "react";

var buttonStyles = ['btn', 'btn--currency', 'btn--service'];

const Button = ({ children, onClick, buttonStyle }) => {

  buttonStyle = buttonStyles.includes(buttonStyle) ? buttonStyle : buttonStyles[0]

  return (
    <button className={`btn ${buttonStyle}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;