import React from "react";
import iconStar from "../assets/img/Icon Estrella blanca.svg";
import iconStarY from "../assets/img/Icon Estrella amarilla.svg";

const Photo = ({ pathImg, clave }) => {
  return (
      <div className="my-column">
        <div className="photo">
          <img src={pathImg} alt="" />
          <p>{clave}</p>
        </div>
        <div className="row pb-3">
          <img className="star--size" src={iconStarY} alt="" />
          <img className="star--size" src={iconStarY} alt="" />
          <img className="star--size" src={iconStarY} alt="" />
          <img className="star--size" src={iconStar} alt="" />
          <img className="star--size" src={iconStar} alt="" />
        </div>
      </div>
  );
};

export default Photo;
