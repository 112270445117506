import React from "react";
import iconCheck from "../assets/img/Icon caja de paloma.png";
import iconSave from "../assets/img/save-regular.svg"

const AgregarGeneralesInmueble = () => {
  return (
    <div className="agregar-generales">
      <div className="agregar-generales__col-1">
        <div className="agregar-generales__contador">
          <p>Inmueble No.</p>
          <p id="cuentaInb">10,100</p>
        </div>

        <div className="formulario">
          <h4 className="formulario__titulo">Principales Datos</h4>
          <form id="form1" className="formulario__contenido" action="">
            <label>Propiedad: </label>
            <input type="text" />
            <input id="buttonCA" type="button" value="CA" />
            <label>Título: </label>
            <input id="gralTitleInput" type="text" name="" />
            <label id="gralDescTitle">Descripción: </label>
            <textarea id="gralDescTA" name=""></textarea>
            <input id="gralGuardar" className="btn--datos-generales" type="submit" value="Guardar" />
          </form>
        </div>

        <div className="formulario">
          <h4 className="formulario__titulo">Elige el tipo de operación</h4>
          <p className="formulario--descripcion">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugiat
            accusamus quisquam.
          </p>
          <form id="form2" className="formulario__contenido" action="">
            <img src={iconCheck} alt="icon" width="25" />
            <p>Venta</p>
            <input type="text" name="" id="" />
            <select name="" id="">
              <option value="mxn">MXN</option>
              <option value="usd">USD</option>
              <option value="eur">EUR</option>
            </select>
            <img src={iconSave} alt="save" width="25" />

            {/* <img src="" alt="money" />
            <p>MXN</p>
            <p>189, 456</p>
            <p>160, 178</p> */}

            <img src={iconCheck} alt="icon" width="25" />
            <p>Renta</p>
            <input type="text" name="" id="" />
            <select name="" id="">
              <option value="mxn">MXN</option>
              <option value="usd">USD</option>
              <option value="eur">EUR</option>
            </select>
            <img src={iconSave} alt="save" width="25" />

            <img src={iconCheck} alt="icon" width="25" />
            <p>Renta Esp.</p>
            <input type="text" name="" id="" />
            <select name="" id="">
              <option value="mxn">MXN</option>
              <option value="usd">USD</option>
              <option value="eur">EUR</option>
            </select>
            <img src={iconSave} alt="save" width="25" />

            <button id="buttonPriorizar">Priorizar información</button>
            <input id="inputGuardar" className="btn--datos-generales" type="submit" value="Guardar todo" />
          </form>
        </div>
      </div>
      <div className="agregar-generales__col-2">
        <div className="formulario">
          <h4 className="formulario__titulo">Ubicación del Inmueble</h4>
          <form id="form3" action="" className="formulario__contenido">
            <label htmlFor="">País: </label>
            <input type="text" />
            <label htmlFor="">Código postal: </label>
            <input type="text" name="" id="" />
            <label htmlFor="">Alcaldía o Municipio: </label>
            <input type="text" name="" id="" />
            <label htmlFor="">Colonia: </label>
            <input type="text" name="" id="" />
            <label htmlFor="">Especificar: </label>
            <input type="text" name="" id="" />
            <label htmlFor="">Calle: </label>
            <input type="text" name="" id="" />
            <div id="buttonsN">
              <button>N° Ext.</button>
              <button>N° Int.</button>
              <button>Manzana</button>
              <button>N° Lote</button>
              <button>N°Torre</button>
              <button>Nivel</button>
              <button>N° Depto.</button>
            </div>
            <label htmlFor="">Entre calles: </label>
            <input type="text" name="" id="callesInput" />
            <input id="buttonSave" className="btn--datos-generales" type="submit" value="Guardar" />
          </form>
        </div>
        <button>Generar Clave</button>
      </div>
    </div>
  );
};

export default AgregarGeneralesInmueble;
