import React from 'react'
import Button from './Button'
import Service from './Service'

const Services = ({venta, renta, rentaEsp}) => {
    return (
        <React.Fragment>
        <div class="item--currency">
          <Button
            children="mxn"
            buttonStyle="btn--currency"
          />
          <Button
            children="usd"
            buttonStyle="btn--currency"
          />
          <Button
            children="eur"
            buttonStyle="btn--currency"
          />
        </div>
        <div className="item--col-2">
          <div className="item--services">
            <Service typeService="Venta" price={venta} />
            <Service typeService="Renta" price={renta} />
            <Service typeService="RentaEsp" price={rentaEsp} />
          </div>
          <div className="item--status">
            <p className="item--status__button general-button centrar-items">
              Rent. x fecha
            </p>
            <p className="item--status__button general-button centrar-items">
            Rent. x fecha
            </p>
            <p className="item--status__button general-button centrar-items">
            Rent. x fecha
            </p>
          </div>
        </div>
        </React.Fragment>
    )
}

export default Services