import React from "react";
import Photo from "./Photo";
import Services from "./Services";
import Details from "./Details";
// import img1 from "../assets/img/casa.jpg"
import iconEditar from "../assets/img/Icon agregar contacto.svg";

const Item = ({
  itemTitle,
  pathImg,
  clave,
  venta,
  renta,
  rentaEsp,
  address,
  company,
  superficie,
  m2construccion,
  recamaras,
  banos,
  namePropietary,
}) => {
  return (
    <div className="item">
      <div className="item--title">
        <p>{itemTitle}</p>
        <p className="item--company">{company}</p>
        <div className="details--actions my-row">
          <p>Completo</p>
          <p class="details--border">C</p>
          <p class="details--border">A</p>
          <img class="details--border" src={iconEditar} alt="" />
          <img class="details--border" src={iconEditar} alt="" />
        </div>
      </div>
      <div className="item--content">
        {/* Columna 1: Foto */}
        <div className="item--first--column">
          <Photo
            // pathImg={img1}
            pathImg={pathImg}
            clave={clave}
          />
        </div>

        {/* Columna 2: Precios */}
        <div className="item--second--column">
          <Services venta={venta} renta={renta} rentaEsp={rentaEsp} />
        </div>

        {/* Columna 3: Características */}
        <div className="item--third-column">
          <Details
            address={address}
            company={company}
            superficie={superficie}
            m2construccion={m2construccion}
            recamaras={recamaras}
            banos={banos}
            namePropietary={namePropietary}
          />
        </div>
      </div>
    </div>
  );
};

export default Item;
