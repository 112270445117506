import React, { useState, useEffect } from "react";

import "../assets/css/datos_empresa.css";

import img_lupa_b from "../assets/img/Icon Lupa B.svg";
import img_contacto from "../assets/img/Icon contacto 3.svg";
import img_propietario from "../assets/img/Icon propietario.svg";
import img_renta from "../assets/img/Icon Int en renta.svg";
import img_compra from "../assets/img/Icon Int en compra.svg";
import img_inquilino from "../assets/img/Icon Inquilino.svg";

import icon_agregar_contacto from "../assets/img/Icon agregar contacto.svg";
import icon_eliminar_contacto from "../assets/img/Icon eliminar contacto.svg";
import icon_asignar_contacto from "../assets/img/Icon Asignacion.svg";

import img_propietarios from "../assets/img/Icon propietario.svg";
import img_int_r from "../assets/img/Icon Int en renta.svg";
import img_int_c from "../assets/img/Icon Int en compra.svg";
import img_inq from "../assets/img/Icon Inquilino.svg";
import img_gral from "../assets/img/Opcion Gral contacto.svg";
// import img_ver_todos from '../assets/img/Icon ver contacto.svg'

import AgregarInmueble from "../assets/img/Agregar Inmueble.svg";
import Asesorblanco from "../assets/img/Asesor blanco.svg";
import IcontipocontactoM from "../assets/img/Icon tipo contacto M.svg";

import CardInmueble from "../componentes/CardInmueble";

import Item from "../componentes/Item";
import img1 from "../assets/img/casa.jpg";
import addHome from "../assets/img/Agregar Inmueble.svg";
import lupa from "../assets/img/Icon Lupa B.svg";
import flechaDer from '../assets/img/Flecha derecha.svg';
import flechaIzq from '../assets/img/Flecha izquierda.svg';

import axios from "axios";

// Una vez creado el id, se habilitan todos los demas campos
const VerInmuebles = ({ cambiarSeccion }) => {
  return (
    <div className="fondo-trabajo text-white">
      <div className={`container-fluid container_principal`}>
        
        <div className={`container filters`}>
          <div className="filter--numero--inmuebles">
            <p className="filter--numero--inmuebles__title">N° de Inmuebles</p>
            <p className="filter--numero--inmuebles__numberImb general-button">
              80,000
            </p>
            <button
              className="btn btn-custom-2 btn-lg"
              onClick={(e) =>
                e.preventDefault() & cambiarSeccion("INMUEBLE / AGREGAR")
              }
            >
              <img
                src={AgregarInmueble}
                alt="AgregarInmueble"
                style={{ width: "20px" }}
              />
            </button>
            <p className="filter--numero--inmuebles__resize">{`◂▸`}</p>
          </div>
          <div className="filter--busqueda">
            <p>Criterio de Busqueda</p>
            <form>
              <button className="filter--busqueda__button general-button">
                <img src={lupa} alt="Icon lupa" />
              </button>
            </form>
          </div>
          <div className="filter--paginacion">
            <p>Página</p>
            <img className="self-center" src={flechaDer} alt="" />
            <p className="general-button self-center w-30">01</p>
            <p className="self-center">de</p>
            <p className="general-button self-center w-30">01</p>
            <img className="self-center" src={flechaIzq} alt="" />
          </div>
        </div>
        <div className="viewport text-white">
          <Item
            itemTitle="Departamento amueblado en renta en Polanco"
            pathImg={img1}
            clave="Clave CDF-32CD32"
            venta="$5,709,000.00"
            renta="$23,000.00"
            rentaEsp="$5,000.00"
            address="Horacio, Polanco, Miguel Hidalgo CDMX"
            company="Bandín Carrera"
            superficie="370m2"
            m2construccion="275m2"
            recamaras="4"
            banos="2.5"
            namePropietary="Marian Melendez Perez"
          />
          <Item
            itemTitle="Departamento amueblado en renta en Polanco"
            pathImg={img1}
            clave="Clave CDF-32CD32"
            venta="$5,709,000.00"
            renta="$23,000.00"
            rentaEsp="$5,000.00"
            address="Horacio, Polanco, Miguel Hidalgo CDMX"
            company="Bandín Carrera"
            superficie="370m2"
            m2construccion="275m2"
            recamaras="4"
            banos="2.5"
            namePropietary="Marian Melendez Perez"
          />
          <Item
            itemTitle="Departamento amueblado en renta en Polanco"
            pathImg={img1}
            clave="Clave CDF-32CD32"
            venta="$5,709,000.00"
            renta="$23,000.00"
            rentaEsp="$5,000.00"
            address="Horacio, Polanco, Miguel Hidalgo CDMX"
            company="Bandín Carrera"
            superficie="370m2"
            m2construccion="275m2"
            recamaras="4"
            banos="2.5"
            namePropietary="Marian Melendez Perez"
          />
          <Item
            itemTitle="Departamento amueblado en renta en Polanco"
            pathImg={img1}
            clave="Clave CDF-32CD32"
            venta="$5,709,000.00"
            renta="$23,000.00"
            rentaEsp="$5,000.00"
            address="Horacio, Polanco, Miguel Hidalgo CDMX"
            company="Bandín Carrera"
            superficie="370m2"
            m2construccion="275m2"
            recamaras="4"
            banos="2.5"
            namePropietary="Marian Melendez Perez"
          />
        </div>
      </div>
    </div>
  );
};

export default VerInmuebles;
